import React from "react";
import styled from "styled-components";

function Footer() {
    return (
        <div className="Footer">
            <h3>+55 41 3338 7611</h3>
            <p className="desktop-only">Taborda Lima & Advogados Associados © 2023 Todos os direitos reservados.</p>
            <a
                href="https://api.whatsapp.com/send/?phone=5541992416343&text=Ol%C3%A1%21+Eu+gostaria+de+agendar+uma+consulta%21&type=phone_number&app_absent=0"
                target="_blank"
                rel="noopener noreferrer"
                className="Footer-Button"
            >
                Agende uma Consulta
            </a>
        </div>
    );
}

export default Footer;
