import React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";

import ErrorPage from "./components/ErrorPage";
import TermosCondicoes from "./components/TermosCondicoes";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "material-icons/iconfont/material-icons.css";
import "./styles/main.css";
import "./styles/nav.css";
import "./styles/sectionviewer.css";
import "./styles/divider.css";
import "./styles/footer.css";
import "./styles/sections/sectioninicio.css";
import "./styles/sections/sectionsobre.css";
import "./styles/sections/sectionexpertise.css";
import "./styles/sections/sectionagendamento.css";
import "./styles/sections/sectionportal.css";
import "./styles/sections/sectioncontato.css";
import "./styles/media_queries.css";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
    },
    {
        path: "termos-condicoes",
        element: <TermosCondicoes />,
    },
]);

const domNode = document.getElementById("root");
const root = createRoot(domNode);

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
