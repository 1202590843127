import React from "react";
import styled from "styled-components";

import imgExpertise from "../../images/sections/civil.png";

function SectionPortalCliente() {
    return (
        <section className="responsive-section SectionViewer-Section Section-Portal-Cliente" id="portal-cliente">
            <div className="Section-Content-Wrapper Portal-Login">
                <div className="Portal-Login-MasterContent">
                    <span className="material-icons Portal-Icone-Usuario">account_circle</span>

                    <div className="Portal-Login-Content">
                        <div className="Input-Container">
                            <span className="material-icons Portal-Icone-Usuario-B">account_circle</span>
                            <input className="Portal-Input Portal-Username" type="text" placeholder="Usuário" />
                        </div>

                        <div className="Input-Container">
                            <span className="material-icons Portal-Icone-Senha">lock</span>
                            <input className="Portal-Input Portal-Password" type="password" placeholder="Senha" />
                        </div>

                        <button className="Portal-Botao Portal-Botao-Acessar">Acessar</button>
                        <button className="Portal-Botao Portal-Botao-Criar">Criar uma Conta</button>
                        <a className="esqueci-senha" href="#portal-cliente">
                            Esqueceu seu email ou senha?
                        </a>
                    </div>
                </div>
            </div>
            <div className="Section-Content-Wrapper-Info Portal-Content-Wrapper">
                <div className="Em-Construcao">
                    <div className="C-Inner">
                        <h1 className="Section-Title Portal-Content-Title">Portal do Cliente</h1>
                        <p className="Section-Content Portal-Content">Aqui você pode acompanhar o andamento de todos os seus processos.</p>
                        <h5>EM CONSTRUÇÃO</h5>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SectionPortalCliente;
