import React, { Component, useState, useEffect, useRef, forwardRef } from "react";
import styled from "styled-components";

import Divider from "../main/Divider";
import SectionInicio from "../sections/SectionInicio";
import SectionSobre from "../sections/SectionSobre";
import SectionExpertise from "../sections/SectionExpertise";
import SectionAgendamento from "../sections/SectionAgendamento";
import SectionPortalCliente from "../sections/SectionPortalCliente";
import SectionContato from "../sections/SectionContato";

function SectionViewer(props) {
    return (
        <div className="SectionViewer">
            <div id="Observer"></div>
            <SectionInicio />
            <Divider />
            <SectionSobre />
            <Divider />
            <SectionExpertise />
            <Divider />
            <SectionAgendamento />
            <Divider />
            <SectionPortalCliente />
            <Divider />
            <SectionContato />
        </div>
    );
}

export default SectionViewer;
