import React from "react";
import styled from "styled-components";

import imgInicio from "../../images/sections/edit/Logo_Inicio.png";

function SectionInicio(props) {
    return (
        <section className="SectionViewer-Section Section-Inicio" id="inicio">
            <img className="Section-Image-Inicio" src={imgInicio}></img>
        </section>
    );
}

export default SectionInicio;
