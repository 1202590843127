import React from "react";
import styled from "styled-components";

import imgLounge from "../../images/sections/edit/lounge_1080px.png";

function SectionAgendamento() {
    return (
        <section className="responsive-section SectionViewer-Section Section-Agendamento" id="agendamento">
            <div className="Section-Content-Wrapper">
                <img className="Section-Image" src={imgLounge}></img>
            </div>
            <div className="Section-Content-Wrapper-Info">
                <h1 className="Section-Title">Agendamento de Consulta</h1>
                <p className="Section-Content">
                    Para marcar uma consulta conosco o processo é bem simples e fácil. Você pode nos ligar, ou caso prefira fazer isto no seu tempo -
                    Basta clicar no link abaixo e nos dizer seu nome, email, telefone e motivo da consulta que logo entraremos em contato pelo
                    WhatsApp.
                </p>

                <a
                    className="Agendamento-Button"
                    href="https://api.whatsapp.com/send/?phone=5541992416343&text=Ol%C3%A1%21+Eu+gostaria+de+agendar+uma+consulta%21&type=phone_number&app_absent=0"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Marcar Consulta!
                </a>
            </div>
        </section>
    );
}

export default SectionAgendamento;
