import React from "react";

export default function TermosCondicoes() {
    console.log("Termos e Condições, Cookies e Política de Privacidade");

    return (
        <>
            <div id="termos-page">
                <h1>Termos e Condições</h1>
                <p>Ao usar este site você concorda em ...</p>
            </div>
        </>
    );
}
