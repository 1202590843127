import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import bandeiraDoBrasil from "../../images/icons/bandeira-brasil.png";
import bandeiraDosUsa from "../../images/icons/bandeira-usa.png";

function Nav(props) {
    const [menuOpen, setMenuOpen] = useState(false);
    const [configOpen, setConfigOpen] = useState(false);
    const [LangIsPT, setLangIsPT] = useState(true);
    const [gAnalytics, setGAnalytics] = useState(false);
    const [gTagManager, setGTagManager] = useState(false);
    const [gAds, setGAds] = useState(false);

    function toggleMenu() {
        setMenuOpen(!menuOpen);
        console.log("menu acionado!");
    }

    function toggleLang() {
        setLangIsPT(!LangIsPT);
        console.log("Idioma Alterado / Language Altered!");
        console.log(LangIsPT);
    }

    function toggleConfig() {
        setConfigOpen(!configOpen);
        console.log("Configurações Acionadas!");
    }

    function toggleGAnalytics() {
        setGAnalytics(!gAnalytics);
        console.log("Google Analytics Ativado!");
    }

    function toggleGTagManager() {
        setGTagManager(!gTagManager);
        console.log("Google Tag Manager Ativado!");
    }

    function toggleGAds() {
        setGAds(!gAds);
        console.log("Google Ads Ativado!");
    }

    console.log(props.sectionActiveList);

    return (
        <div className="Nav">
            <div className="Nav-Menu-Header">
                <div className="logo-container">
                    <h1>TABORDA LIMA</h1>
                    <p>&</p>
                    <h2>Advogados Associados</h2>
                </div>

                <div className="menu-anchor-list primary-menu-anchor-list desktop-only">
                    <a href="#inicio" className={props.sectionActiveList.InicioActive ? "desktop-only-anchor icon-active" : "desktop-only-anchor"}>
                        <p>Início</p>
                        <span className="material-icons desktop-only-icon">home</span>
                    </a>

                    <a href="#sobre" className={props.sectionActiveList.SobreActive ? "desktop-only-anchor icon-active" : "desktop-only-anchor"}>
                        <p>Sobre</p>
                        <span className="material-icons desktop-only-icon">foundation</span>
                    </a>

                    <a
                        href="#expertise"
                        className={props.sectionActiveList.ExpertiseActive ? "desktop-only-anchor icon-active" : "desktop-only-anchor"}
                    >
                        <p>Áreas de Expertise</p>
                        <span className="material-icons desktop-only-icon">work</span>
                    </a>

                    <a
                        href="#agendamento"
                        className={props.sectionActiveList.AgendamentoActive ? "desktop-only-anchor icon-active" : "desktop-only-anchor"}
                    >
                        <p>Agendar Consulta</p>
                        <span className="material-icons desktop-only-icon">pending_actions</span>
                    </a>

                    <a
                        href="#portal-cliente"
                        className={props.sectionActiveList.PortalActive ? "desktop-only-anchor icon-active" : "desktop-only-anchor"}
                    >
                        <p>Portal do Cliente</p>
                        <span className="material-icons desktop-only-icon">webhook</span>
                    </a>

                    <a href="#contato" className={props.sectionActiveList.ContatoActive ? "desktop-only-anchor icon-active" : "desktop-only-anchor"}>
                        <p>Contato</p>
                        <span className="material-icons desktop-only-icon">perm_phone_msg</span>
                    </a>
                </div>

                <div className="Language-Selector">
                    <span className="material-icons Lang-Icon">translate</span>
                    <img
                        key="lang01"
                        className={LangIsPT ? "Lang-Bandeira Lang-Active" : "Lang-Bandeira"}
                        src={bandeiraDoBrasil}
                        onClick={toggleLang}
                    ></img>
                    <img
                        key="lang02"
                        className={LangIsPT ? "Lang-Bandeira" : "Lang-Bandeira Lang-Active"}
                        src={bandeiraDosUsa}
                        onClick={toggleLang}
                    ></img>
                </div>

                <a className={menuOpen ? "Nav-Burger burger-active" : "Nav-Burger"} href="#" onClick={toggleMenu}>
                    <div className="burger-line burger-line-1"></div>
                    <div className="burger-line burger-line-2"></div>
                    <div className="burger-line burger-line-3"></div>
                </a>
            </div>

            <div className={menuOpen ? "Nav-Menu Nav-Menu-Active" : "Nav-Menu"} id="Nav-Menu">
                <div className="menu-anchor-list secondary-menu-anchor-list">
                    <a href="#inicio" className={props.sectionActiveList.InicioActive ? "icon-active" : " "}>
                        <p>Início</p>
                        <span className="material-icons">home</span>
                    </a>

                    <a href="#sobre" className={props.sectionActiveList.SobreActive ? "icon-active" : " "}>
                        <p>Sobre o Escritório</p>
                        <span className="material-icons">foundation</span>
                    </a>

                    <a href="#expertise" className={props.sectionActiveList.ExpertiseActive ? "icon-active" : " "}>
                        <p>Áreas de Expertise</p>
                        <span className="material-icons">work</span>
                    </a>

                    <a href="#agendamento" className={props.sectionActiveList.AgendamentoActive ? "icon-active" : " "}>
                        <p>Agendar Consulta</p>
                        <span className="material-icons">pending_actions</span>
                    </a>

                    <a href="#portal-cliente" className={props.sectionActiveList.PortalActive ? "icon-active" : " "}>
                        <p>Portal do Cliente</p>
                        <span className="material-icons">webhook</span>
                    </a>

                    <a href="#contato" className={props.sectionActiveList.ContatoActive ? "icon-active" : " "}>
                        <p>Contato</p>
                        <span className="material-icons">perm_phone_msg</span>
                    </a>

                    <a href="#configuração" className={configOpen ? "Config-Active" : " "} onClick={toggleConfig}>
                        <p>Configurações</p>
                        <span className="material-icons">settings</span>
                    </a>
                </div>

                <div className={configOpen ? "Config ConfigOpen" : "Config"}>
                    <h3>
                        Configurações <br></br> e Permissões
                    </h3>

                    <div className="Config-Options Config-1">
                        <p className="Option-title">Idioma</p>
                        <button onClick={toggleLang}>{LangIsPT ? "Português" : "English"}</button>
                    </div>

                    <div className="Config-Options Config-2">
                        <p className="Option-title">Notificações</p>
                        <button>Desligadas</button>
                    </div>

                    <div className="Config-Options Config-3">
                        <p className="Option-title">Tema Escuro</p>
                        <button>Ligado</button>
                    </div>

                    <div className="Config-Options Config-4">
                        <p className="Option-title">Usuário</p>
                        <div className="Option-User-Status">
                            <span className="material-icons Portal-Icone-Usuario-B">account_circle</span>
                            <p>Visitante</p>
                        </div>
                        <button>Fazer Login</button>
                    </div>

                    <div className="Config-Options Config-5">
                        <p className="Option-title">Cookies Permitidos</p>

                        <div class="config-sub-item">
                            <p>Google Analytics</p>
                            <button onClick={toggleGAnalytics}>{gAnalytics ? "Ligado" : "Desligado"}</button>
                        </div>
                        <div class="config-sub-item">
                            <p>Google Adsense</p>
                            <button onClick={toggleGAds}>{gAds ? "Ligado" : "Desligado"}</button>
                        </div>
                        <div class="config-sub-item">
                            <p>Google Tag Manager</p>
                            <button onClick={toggleGTagManager}>{gTagManager ? "Ligado" : "Desligado"}</button>
                        </div>
                    </div>

                    <div className="Config-Options Config-6">
                        <p className="Option-title">Termos e Condições</p>

                        <div class="config-sub-item">
                            <p>Política de Privacidade</p>
                            <button>Ler</button>
                        </div>
                        <div class="config-sub-item">
                            <p>Política de Cookies</p>
                            <button>Ler</button>
                        </div>
                        <div class="config-sub-item">
                            <p>Termos Gerais</p>
                            <button>Ler</button>
                        </div>
                    </div>
                </div>

                <div className="Social-Media">
                    {/* Whatsapp */}
                    <a
                        href="https://api.whatsapp.com/send/?phone=5541992416343&text=Ol%C3%A1%21+Eu+gostaria+de+agendar+uma+consulta%21&type=phone_number&app_absent=0"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path d="M224 122.8c-72.7 0-131.8 59.1-131.9 131.8 0 24.9 7 49.2 20.2 70.1l3.1 5-13.3 48.6 49.9-13.1 4.8 2.9c20.2 12 43.4 18.4 67.1 18.4h.1c72.6 0 133.3-59.1 133.3-131.8 0-35.2-15.2-68.3-40.1-93.2-25-25-58-38.7-93.2-38.7zm77.5 188.4c-3.3 9.3-19.1 17.7-26.7 18.8-12.6 1.9-22.4.9-47.5-9.9-39.7-17.2-65.7-57.2-67.7-59.8-2-2.6-16.2-21.5-16.2-41s10.2-29.1 13.9-33.1c3.6-4 7.9-5 10.6-5 2.6 0 5.3 0 7.6.1 2.4.1 5.7-.9 8.9 6.8 3.3 7.9 11.2 27.4 12.2 29.4s1.7 4.3.3 6.9c-7.6 15.2-15.7 14.6-11.6 21.6 15.3 26.3 30.6 35.4 53.9 47.1 4 2 6.3 1.7 8.6-1 2.3-2.6 9.9-11.6 12.5-15.5 2.6-4 5.3-3.3 8.9-2 3.6 1.3 23.1 10.9 27.1 12.9s6.6 3 7.6 4.6c.9 1.9.9 9.9-2.4 19.1zM400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM223.9 413.2c-26.6 0-52.7-6.7-75.8-19.3L64 416l22.5-82.2c-13.9-24-21.2-51.3-21.2-79.3C65.4 167.1 136.5 96 223.9 96c42.4 0 82.2 16.5 112.2 46.5 29.9 30 47.9 69.8 47.9 112.2 0 87.4-72.7 158.5-160.1 158.5z" />
                        </svg>
                    </a>

                    {/* LinkedIn */}
                    <a href="https://www.linkedin.com/in/taborda-lima-advogados-aduaneiros-ab872526/" target="_blank" rel="noopener noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
                        </svg>
                    </a>

                    {/* Facebook */}
                    <a href="https://www.facebook.com/profile.php?id=100066974632889" target="_blank" rel="noopener noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" />
                        </svg>
                    </a>

                    {/* Instagram */}
                    <a href="#">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path d="M224,202.66A53.34,53.34,0,1,0,277.36,256,53.38,53.38,0,0,0,224,202.66Zm124.71-41a54,54,0,0,0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71.05-6.43,94.33S91,329.26,99.32,350.33a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54,54,0,0,0,30.41-30.41c8.35-21,6.43-71.05,6.43-94.33S357.1,182.74,348.75,161.67ZM224,338a82,82,0,1,1,82-82A81.9,81.9,0,0,1,224,338Zm85.38-148.3a19.14,19.14,0,1,1,19.13-19.14A19.1,19.1,0,0,1,309.42,189.74ZM400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM382.88,322c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67.05C384.37,216.44,384.37,295.56,382.88,322Z" />
                        </svg>
                    </a>
                </div>

                <div className="nav-footer">
                    <p className="trademark-year mobile-only">Taborda Lima - 2023</p>

                    <div className="design-authors">
                        <p>Webdesign por</p>
                        <a href="https://pragmata.myportfolio.com/" target="_blank" rel="noopener noreferrer">
                            <p>Pragmata</p>
                            <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 174.87 172.3">
                                <g id="Layer_1-2">
                                    <g>
                                        <path d="m108.11,91.75c3.38-8.24,3.55-11.42.64-14.31-1.64-1.52-2.44-1.99-7.65-4.13l-16.35-6.71-10.63,25.92,15.5,6.36c11.63,4.77,13.97,3.89,18.49-7.13Z" />
                                        <path d="m173.39,96.03l-36.02-19.05c-1.6-.85-1.98-2.97-.76-4.32l27.33-30.22c1.81-2,0-5.14-2.64-4.58l-39.84,8.56c-1.77.38-3.42-1.01-3.36-2.82l1.51-40.72c.1-2.69-3.31-3.93-4.97-1.81l-25.02,32.16c-1.11,1.43-3.27,1.43-4.38,0L60.22,1.08c-1.65-2.13-5.07-.89-4.97,1.81l1.51,40.72c.07,1.81-1.59,3.2-3.36,2.82l-39.84-8.56c-2.64-.57-4.45,2.58-2.64,4.58l27.33,30.22c1.21,1.34.84,3.47-.76,4.32L1.48,96.03c-2.38,1.26-1.75,4.83.92,5.2l40.36,5.59c.23.03.44.09.64.17,1.42.56,3.03-.13,3.61-1.54l25.39-61.88,32.1,13.17q10.42,4.27,13.03,5.49c7.6,3.54,12.56,9.4,13.76,16.12.97,5.92-.2,11.52-4.42,21.82-5.02,12.23-9.91,18.29-16.22,20.23-4.7,1.47-9.88,1.32-14.78-.54-.78-.18-3.32-1.22-12.99-4.9l-12.79-5.25c-1.43-.59-3.07.1-3.66,1.53l-7.46,18.19c-.59,1.43-2.22,2.12-3.66,1.53l-13.37-5.48c-1.43-.59-3.08.1-3.66,1.54l-8.69,21.38c-1.01,2.5,1.77,4.83,4.05,3.4l34.51-21.67c1.53-.96,3.56-.22,4.12,1.5l12.51,38.78c.83,2.57,4.46,2.57,5.28,0l12.51-38.78c.56-1.72,2.58-2.46,4.12-1.5l34.51,21.67c2.28,1.43,5.06-.9,4.05-3.4l-15.35-37.75c-.68-1.68.4-3.55,2.19-3.8l40.36-5.59c2.67-.37,3.3-3.94.92-5.2Z" />
                                    </g>
                                </g>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Nav;
