import React from "react";
import styled from "styled-components";

import imgInter from "../../images/sections/edit/internacionalC_700px.png";
import imgEnt from "../../images/sections/edit/art_700px.png";
import imgAduan from "../../images/sections/edit/comercioC_700px.png";
import imgCivil from "../../images/sections/edit/civil_700px.png";
import imgMigra from "../../images/sections/edit/migratorio_700px.png";

function SectionExpertise() {
    const [isFlipped1, setIsFlipped1] = React.useState(false);
    const [isFlipped2, setIsFlipped2] = React.useState(false);
    const [isFlipped3, setIsFlipped3] = React.useState(false);
    const [isFlipped4, setIsFlipped4] = React.useState(false);

    const handleFlip1 = () => {
        setIsFlipped1(!isFlipped1);
    };

    const handleFlip2 = () => {
        setIsFlipped2(!isFlipped2);
    };

    const handleFlip3 = () => {
        setIsFlipped3(!isFlipped3);
    };

    const handleFlip4 = () => {
        setIsFlipped4(!isFlipped4);
    };

    return (
        <section className="SectionViewer-Section Section-Expertise" id="expertise">
            <h1 className="Section-Title">Áreas de Expertise</h1>
            <div className="Section-Content-Wrapper Expertise-Wrapper">
                <div className="Expertise-Container-Wrapper Expertise-First">
                    <div className={isFlipped1 ? "Expertise-Container card-active" : "Expertise-Container"}>
                        <div className="Expertise-Container-Frontside">
                            <img src={imgInter} className="Expertise-Image"></img>
                            <h3 className="Expertise-Titulo">Direito Internacional</h3>
                            <p className="Expertise-Descricao">
                                Assessoramento jurídico sobre investimento, comércio, propriedade intelectual e admnistração conjugando interesses de
                                brasileiros no exterior e de estrangeiros no Brasil.
                            </p>
                            <button className="Expertise-Botao" onClick={handleFlip1}>
                                Saiba mais!
                            </button>
                        </div>
                        <div className="Expertise-Container-Backside">
                            <h3>Direito Internacional</h3>
                            <h4>Serviços</h4>
                            <div className="Backside-Descricao">
                                <div className="Backside-Topic">
                                    <span className="material-icons Backside-Topic-Icon">plagiarism</span>
                                    <p>Análise e Elaboração de Contratos</p>
                                </div>

                                <div className="Backside-Topic">
                                    <span className="material-icons Backside-Topic-Icon">psychology</span>
                                    <p>Registro de Propriedade Intelectual</p>
                                </div>

                                <div className="Backside-Topic">
                                    <span className="material-icons Backside-Topic-Icon">language</span>
                                    <p>Internacionalização de Franquias</p>
                                </div>

                                <div className="Backside-Topic">
                                    <span className="material-icons Backside-Topic-Icon">language</span>
                                    <p>Estruturação Jurídica de Operações Comerciais Internacionais</p>
                                </div>

                                <div className="Backside-Topic">
                                    <span className="material-icons Backside-Topic-Icon">gavel</span>
                                    <p>Proteção e Defesa de Direitos nos Tribunais e Câmaras de Arbitragem Internacional</p>
                                </div>

                                <div className="Backside-Topic">
                                    <span className="material-icons Backside-Topic-Icon">handshake</span>
                                    <p>Pedidos de Waiver</p>
                                </div>
                            </div>
                            <button className="Expertise-Botao" onClick={handleFlip1}>
                                Voltar
                            </button>
                        </div>
                    </div>
                </div>

                <div className="Expertise-Container-Wrapper">
                    <div className={isFlipped2 ? "Expertise-Container card-active" : "Expertise-Container"}>
                        <div className="Expertise-Container-Frontside">
                            <img src={imgMigra} className="Expertise-Image"></img>
                            <h3 className="Expertise-Titulo">Direito Migratório</h3>
                            <p className="Expertise-Descricao">
                                Assessoramento na obtenção dos respectivos vistos para permitir que a pessoa dirija seu negócio ou seja empregada nos
                                EUA.
                            </p>
                            <button className="Expertise-Botao" onClick={handleFlip2}>
                                Saiba mais!
                            </button>
                        </div>

                        <div className="Expertise-Container-Backside">
                            <h3>Direito Migratório</h3>
                            <h4>Serviços</h4>

                            <div className="Backside-Descricao">
                                <div className="Backside-Topic">
                                    <span className="material-icons Backside-Topic-Icon">plagiarism</span>
                                    <p>Análise e Elaboração de Contratos</p>
                                </div>

                                <div className="Backside-Topic">
                                    <span className="material-icons Backside-Topic-Icon">recent_actors</span>
                                    <p>Emissão de Vistos de Trabalho</p>
                                </div>

                                <div className="Backside-Topic">
                                    <span className="material-icons Backside-Topic-Icon">recent_actors</span>
                                    <p>Emissão de Vistos de Investidor</p>
                                </div>

                                <div className="Backside-Topic">
                                    <span className="material-icons Backside-Topic-Icon">recent_actors</span>
                                    <p>Emissão de Visto de Artista</p>
                                </div>

                                <div className="Backside-Topic">
                                    <span className="material-icons Backside-Topic-Icon">recent_actors</span>
                                    <p>Emissão de Vistos de Casamento e Noivado</p>
                                </div>

                                <div className="Backside-Topic">
                                    <span className="material-icons Backside-Topic-Icon">recent_actors</span>
                                    <p>Emissão de Green Card por Habilidade Extraordinária</p>
                                </div>

                                <div className="Backside-Topic">
                                    <span className="material-icons Backside-Topic-Icon">recent_actors</span>
                                    <p>Emissão de Vistos Religiosos</p>
                                </div>

                                <div className="Backside-Topic">
                                    <span className="material-icons Backside-Topic-Icon">language</span>
                                    <p>Internacionalização de Igrejas</p>
                                </div>
                            </div>

                            <button className="Expertise-Botao" onClick={handleFlip2}>
                                Voltar
                            </button>
                        </div>
                    </div>
                </div>

                <div className="Expertise-Container-Wrapper">
                    <div className={isFlipped3 ? "Expertise-Container card-active" : "Expertise-Container"}>
                        <div className="Expertise-Container-Frontside">
                            <img src={imgAduan} className="Expertise-Image"></img>
                            <h3 className="Expertise-Titulo">Direito Aduaneiro</h3>
                            <p className="Expertise-Descricao">
                                Apoio em operações de importação e exportação, elaboração de planejamento tributário para as operações de Comércio
                                Exterior, incluindo concessão de regimes aduaneiros especiais, benefícios e incentivos fiscais.
                            </p>
                            <button className="Expertise-Botao" onClick={handleFlip3}>
                                Saiba mais!
                            </button>
                        </div>

                        <div className="Expertise-Container-Backside">
                            <h3>Direito Aduaneiro</h3>
                            <h4>Serviços</h4>

                            <div className="Backside-Descricao">
                                <div className="Backside-Topic">
                                    <span className="material-icons Backside-Topic-Icon">plagiarism</span>
                                    <p>Análise e Elaboração de Contratos</p>
                                </div>
                                <div className="Backside-Topic">
                                    <span className="material-icons Backside-Topic-Icon">paid</span>
                                    <p>Elaboração de Planejamento Tributário</p>
                                </div>

                                <div className="Backside-Topic">
                                    <span className="material-icons Backside-Topic-Icon">percent</span>
                                    <p>Consultoria e análise de barreiras tarifárias e não tarifárias</p>
                                </div>

                                <div className="Backside-Topic">
                                    <span className="material-icons Backside-Topic-Icon">fact_check</span>
                                    <p>Licenciamento de Importação</p>
                                </div>

                                <div className="Backside-Topic">
                                    <span className="material-icons Backside-Topic-Icon">paid</span>
                                    <p>Acessoria para Regimes Aduaneiros Especiais, Benefícios e Incentivos Fiscais</p>
                                </div>

                                <div className="Backside-Topic">
                                    <span className="material-icons Backside-Topic-Icon">local_offer</span>
                                    <p>Acessoria Alfandegária</p>
                                </div>

                                <div className="Backside-Topic">
                                    <span className="material-icons Backside-Topic-Icon">content_paste</span>
                                    <p>Emissão de Pareceres Técnicos</p>
                                </div>
                            </div>

                            <button className="Expertise-Botao" onClick={handleFlip3}>
                                Voltar
                            </button>
                        </div>
                    </div>
                </div>

                <div className="Expertise-Container-Wrapper Expertise-Last">
                    <div className={isFlipped4 ? "Expertise-Container card-active" : "Expertise-Container"}>
                        <div className="Expertise-Container-Frontside">
                            <img src={imgCivil} className="Expertise-Image"></img>
                            <h3 className="Expertise-Titulo">Direito Civil</h3>
                            <p className="Expertise-Descricao">
                                Atuamos em uma vasta gama de áreas do direito civil que incluem: Direito Bancário, Direito do Consumidor, Direito
                                Contratual, Direito Imobilário, Direito de Família e Sucessões, Indenizações, Arbitragens e Mediações.
                            </p>
                            <button className="Expertise-Botao" onClick={handleFlip4}>
                                Saiba mais!
                            </button>
                        </div>

                        <div className="Expertise-Container-Backside">
                            <h3>Direito Civil</h3>
                            <h4>Serviços</h4>
                            <div className="Backside-Descricao">
                                <div className="Backside-Topic">
                                    <span className="material-icons Backside-Topic-Icon">history_edu</span>
                                    <p>Direito Contratual</p>
                                </div>
                                <div className="Backside-Topic">
                                    <span className="material-icons Backside-Topic-Icon">account_balance</span>
                                    <p>Direito Bancário</p>
                                </div>

                                <div className="Backside-Topic">
                                    <span className="material-icons Backside-Topic-Icon">settings_accessibility</span>
                                    <p>Direito do Consumidor</p>
                                </div>

                                <div className="Backside-Topic">
                                    <span className="material-icons Backside-Topic-Icon">location_city</span>
                                    <p>Direito Imobilário</p>
                                </div>

                                <div className="Backside-Topic">
                                    <span className="material-icons Backside-Topic-Icon">family_restroom</span>
                                    <p>Direito de Família e Sucessões Nacional e Internacional</p>
                                </div>

                                <div className="Backside-Topic">
                                    <span className="material-icons Backside-Topic-Icon">handshake</span>
                                    <p>Indenizações</p>
                                </div>
                            </div>
                            <button className="Expertise-Botao" onClick={handleFlip4}>
                                Voltar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SectionExpertise;
