import React, { Component, useState, useEffect, useRef } from "react";
import styled from "styled-components";

import Nav from "./main/Nav";
import CookieConsent from "./main/CookieConsent";
import SectionViewer from "./main/SectionViewer";
import Footer from "./main/Footer";

import { CookiesProvider } from "react-cookie";
import { useCookies } from "react-cookie";

function App() {
    //Styled Components
    //const { classes } = useStyles();

    //Cookies
    const [cookies, setCookie, removeCookie] = useCookies(["cookieConsent"]);

    //States
    const [sectionActiveList, setSectionActiveList] = useState({
        InicioActive: true,
        SobreActive: null,
        ExpertiseActive: null,
        AgendamentoActive: null,
        PortalActive: null,
        ContatoActive: null,
    });

    function handleScroll() {
        //console.log(window.scrollY);
    }

    //Observer

    const observerOptions = {
        root: document.getElementById("Observer"),
        rootMargin: "0px",
        threshold: 0.6,
    };

    useEffect(() => {
        //Observer 1 - Inicio
        const observer1 = new IntersectionObserver((entries) => {
            const entry = entries[0];

            setSectionActiveList({
                ...sectionActiveList,
                InicioActive: entry.isIntersecting,
                SobreActive: false,
                ExpertiseActive: false,
                AgendamentoActive: false,
                PortalActive: false,
                ContatoActive: false,
            });
        }, observerOptions);

        observer1.observe(document.getElementById("inicio"));

        //Observer 2 - Sobre
        const observer2 = new IntersectionObserver((entries) => {
            const entry = entries[0];
            setSectionActiveList({
                ...sectionActiveList,
                InicioActive: false,
                SobreActive: entry.isIntersecting,
                ExpertiseActive: false,
                AgendamentoActive: false,
                PortalActive: false,
                ContatoActive: false,
            });
        }, observerOptions);
        observer2.observe(document.getElementById("sobre"));

        //Observer 3 - Expertise
        const observer3 = new IntersectionObserver((entries) => {
            const entry = entries[0];
            setSectionActiveList({
                ...sectionActiveList,
                InicioActive: false,
                SobreActive: false,
                ExpertiseActive: entry.isIntersecting,
                AgendamentoActive: false,
                PortalActive: false,
                ContatoActive: false,
            });
        }, observerOptions);

        observer3.observe(document.getElementById("expertise"));

        //Observer 4 - Agendamento
        const observer4 = new IntersectionObserver((entries) => {
            const entry = entries[0];
            setSectionActiveList({
                ...sectionActiveList,
                InicioActive: false,
                SobreActive: false,
                ExpertiseActive: false,
                AgendamentoActive: entry.isIntersecting,
                PortalActive: false,
                ContatoActive: false,
            });
        }, observerOptions);

        observer4.observe(document.getElementById("agendamento"));

        //Observer 5 - Portal
        const observer5 = new IntersectionObserver((entries) => {
            const entry = entries[0];
            setSectionActiveList({
                ...sectionActiveList,
                InicioActive: false,
                SobreActive: false,
                ExpertiseActive: false,
                AgendamentoActive: false,
                PortalActive: entry.isIntersecting,
                ContatoActive: false,
            });
        }, observerOptions);

        observer5.observe(document.getElementById("portal-cliente"));

        //Observer 6 - Contato
        const observer6 = new IntersectionObserver((entries) => {
            const entry = entries[0];
            setSectionActiveList({
                ...sectionActiveList,
                InicioActive: false,
                SobreActive: false,
                ExpertiseActive: false,
                AgendamentoActive: false,
                PortalActive: false,
                ContatoActive: entry.isIntersecting,
            });
        }, observerOptions);

        observer6.observe(document.getElementById("contato"));
    }, []);

    console.log("Visibilidade da Seção 1: " + sectionActiveList.InicioActive);

    console.log("Visibilidade da Seção 2: " + sectionActiveList.SobreActive);

    console.log("Visibilidade da Seção 3: " + sectionActiveList.ExpertiseActive);

    console.log("Visibilidade da Seção 4: " + sectionActiveList.AgendamentoActive);

    console.log("Visibilidade da Seção 5: " + sectionActiveList.PortalActive);

    console.log("Visibilidade da Seção 6: " + sectionActiveList.ContatoActive);

    return (
        <>
            <CookiesProvider>
                <Nav sectionActiveList={sectionActiveList} />
                <CookieConsent />
                <SectionViewer />
                <Footer />
            </CookiesProvider>
        </>
    );
}

export default App;
