import React from "react";
import styled from "styled-components";

import imgExpertise from "../../images/sections/civil.png";

function SectionContato() {
    return (
        <section className="responsive-section SectionViewer-Section Section-Contato" id="contato">
            <div className="Section-Content-Wrapper">
                <iframe
                    className="Contato-Mapa-Iframe"
                    referrerPolicy="no-referrer-when-downgrade"
                    src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBF5YpmWvN7qja4E5glWW6sTT7k0KmZNTs&amp;q=tabordalimaadvogadosaduaneiros&amp;zoom=15"
                    allowFullScreen=""
                ></iframe>
            </div>

            <div className="Section-Content-Wrapper-Info">
                <h1 className="Section-Title">Contato</h1>
                <p className="Section-Content Contato-Content">
                    Sinta-se a vontade para nos contatar, estamos à disposição para sanar quaisquer dúvidas que você possa ter.
                </p>

                <div className="Contato-Item-Wrapper">
                    <span className="material-icons Contato-Icones">perm_phone_msg</span>
                    <h3 className="Contato-Telefone">+55 41 992 416 343</h3>
                </div>

                <div className="Contato-Item-Wrapper">
                    <span className="material-icons Contato-Icones">fax</span>
                    <h3 className="Contato-Telefone">+55 41 3338 7611</h3>
                </div>

                <div className="Contato-Item-Wrapper">
                    <span className="material-icons Contato-Icones">mail</span>
                    <h3 className="Contato-Email">contato@advtabordalima.com.br</h3>
                </div>

                <div className="Contato-Item-Wrapper">
                    <span className="material-icons Contato-Icones">fmd_good</span>
                    <h3 className="Contato-Endereco">Rua Nilo Peçanha, nº648 Centro Cívico, Curitiba - PR</h3>
                </div>
            </div>
        </section>
    );
}

export default SectionContato;
