import React, { Component, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useCookies } from "react-cookie";

export default function CookieConsent() {
    const [cookies, setCookie, removeCookie] = useCookies(["cookieConsent"]);

    const giveCookieConsent = () => {
        setCookie("cookieConsent", true, { path: "/" });
    };

    return (
        <div className={!cookies.cookieConsent ? "lgpd-cont lgpd-active" : "lgpd-cont lgpd-inactive"}>
            <span className="material-icons lgpd-icon">cookie</span>
            <p>Este site utiliza cookies para melhorar a sua experiência de navegação. </p>
            <p>
                Ao continuar a navegar você concorda com nossos
                <a href="#">Termos de Privacidade</a> e com nossa <a href="#">Política de Cookies</a>.
            </p>

            <p>Caso deseje selecionar especificamente quais cookies este site utiliza, acesse a aba de configurações no menu do site.</p>

            <button className="lgpd-but" onClick={giveCookieConsent}>
                Aceitar
            </button>
        </div>
    );
}
